/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation-unobtrusive';

$(async function () {

    // Responsive menu
    $('header').each(function () {
        var element = $(this),
            body = $(document.body),
            responsiveNav = $('.responsive-nav');

        var toggler = $('<div class="toggler toggler--closed"><span>Menu</span></div>').click(function () {
            body.toggleClass('open');
            responsiveNav.toggleClass('collapsed');
            toggler.toggleClass('toggler--closed');
        }).appendTo(element);
    });

    // Lightbox
    $('#container:has([rel=lightbox])').each(async function () {
        await import('lightgallery');

        $(this).lightGallery({
            selector: '[rel=lightbox]',
            download: false,
            thumbnail: false,
            fullScreen: false,
            actualSize: false,
            hash: false
        });
	});

	// Slideshow
	$('.slider').each(async function () {
		await import('lightslider');

		var element = $(this);
		var cssClass = element.attr('class');
		if (element.children('li').length > 1) {
			element.lightSlider({
				item: 1,
				slideMargin: 0,
				addClass: cssClass,
				cssEasing: 'linear',
				auto: true,
				loop: true,
				adaptiveHeight: true,
				pause: 6000,
				controls: false,
				pager: true
			});
		}
	});

	// Match height
	async function matchHeights() {
		await import('jquery-match-height');

		var element = $(this);
		$('.match-min-height').matchHeight({
			property: 'min-height'
		});
	};

	if ($('.match-min-height')[0]) {
		matchHeights();
	}

	// Google maps
	$('.Terratype').each(function () {
		var element = $(this);

		function resizeMaps() {
			element.css('width', (element.width() + $(window).width() - (element.offset().left + element.width())))
		}

		resizeMaps();

		$(window).resize(function () {
			resizeMaps();
		})
	});

	// Route form
	$('.form--route').each(function () {
		var element = $(this);
		var button = element.find('button');
		var destinationAddress = element.find('input[type=text]');
		var destination = element.find('#To');

		// Route on button click
		button.click(function (e) {
			var route = {
				saddr: destinationAddress.val(),
				daddr: destination.val()
			};

			window.open('https://maps.google.com/?' + $.param(route), '_blank');

			e.preventDefault();
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash);

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top
			}, 500);

			return false;
		}
	});

	// Jump to submitted form
	$('.umbraco-forms-form[id]:has(form)').each(function () {
		var umbForm = $(this), form = umbForm.find('form');
		form.attr('action', form.attr('action') + '#' + umbForm.attr('id'));
	});
});